import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

import { getSrc } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import Page from "../Layout/Page";
import { QueryResult, Artwork } from "../utils/types";
import getGatsbyImage from "../utils/getGatsbyImage";
import { Helmet } from "react-helmet";
import { getSeoLinks, getSeoMetas } from "../components/SeoHelper";

export const query = graphql`
  {
    aube: allImageSharp(filter: { original: { src: { regex: "/aube/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    blood: allImageSharp(filter: { original: { src: { regex: "/blood/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    love: allImageSharp(filter: { original: { src: { regex: "/Love/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    obsession: allImageSharp(
      filter: { original: { src: { regex: "/obsession/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    uglyGirl: allImageSharp(
      filter: { original: { src: { regex: "/ugly girl/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
  }
`;

const ArtItem: React.VFC<{
  title: string;
  description: string;
  souncloudUrl: string;
  src: string;
}> = ({ title, description, src, souncloudUrl }) => {
  const scrollTo = React.useRef<any>();
  React.useEffect(() => {
    const urlHash = window.location.href.split("#")[1];
    if (
      urlHash &&
      title.replace(" ", "-").toLowerCase() === urlHash &&
      scrollTo.current
    ) {
      scrollTo.current.scrollIntoView(true);
    }
  }, [scrollTo]);
  return (
    <React.Fragment>
      <Grid item md={6} ref={scrollTo}>
        <Stack spacing={1}>
          <Box>
            <Typography variant="h2">{title}</Typography>
            <Typography>{description}</Typography>
            {/* <span
              id={title.replace(" ", "-").toLowerCase()}
              style={{
                visibility: "hidden",
                height: "50vh",
                marginTop: "-50vh",
                display: "block",
              }}
            /> */}
            {/* <Box
              component="span"
              id={title.replace(" ", "-").toLowerCase()}
              sx={{
                visibility: "hidden",
                height: { md: "50vh" },
                marginTop: { md: "-50vh" },
                display: "block",
              }}
            /> */}
          </Box>
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src={souncloudUrl}
          ></iframe>
        </Stack>
      </Grid>
      <Grid md={6} item>
        <img
          src={src}
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          alt={title}
        />
      </Grid>
    </React.Fragment>
  );
};

function getArtworksFromData(data: {
  aube: QueryResult;
  blood: QueryResult;
  love: QueryResult;
  obsession: QueryResult;
  uglyGirl: QueryResult;
}): Artwork[] {
  return [
    {
      src: getSrc(getGatsbyImage(data.blood)) || "",
      title: "Blood",
      description: "Acrylics on panel, FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.obsession)) || "",
      title: "Obsession (digital version)",
      description: "FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.love)) || "",
      title: "Love",
      description: "Oils on panel, FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.uglyGirl)) || "",
      title: "Ugly Girl",
      description: "Acrylics on panel, FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.aube)) || "",
      title: "Aube",
      description: "Oils on panel, FEELINGS, 2022",
    },
  ];
}

const FeelingsPage = ({ data }: any) => {
  const artworks = getArtworksFromData(data);
  return (
    <Page title={"feelings"}>
      <Helmet
        title="Feelings"
        titleTemplate="%s · Lisa Angrino"
        meta={getSeoMetas({
          title: "Feelings",
          path: "feelings",
          description:
            "Lisa Angrino is a visual artist and philosopher from Colombia. Discover her Feelings project where she explores music through her paintings.",
        })}
        link={getSeoLinks({ path: "feelings" })}
      />

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {/* <Grid
          md={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Typography>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Typography>
        </Grid> */}
        <ArtItem
          title="Obsession"
          description="It all starts with an obsession. Some kind of pounding idea you can’t get rid off. It kills you and so do the nightmares that come after it. In those nightmares I can hear a baby crying, I start to look for him and, surprisingly, the more I chase the sound the more unclear it becomes. I lay down and close my eyes with my hands on my belly, something beats in my womb. “Mama” I can hear. He never left, he is always there."
          souncloudUrl="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/67759427&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          src={artworks[1].src}
        />
        <ArtItem
          title="Ugly Girl"
          description="“Ugly girl” is guilt, loneliness and self destruction. Not as a victim, but as the one who inflicts harm on others. She is ugly because she is mean, she is in the bell jar even though she hates it. She is mean because she doesn’t want to be hurt, she wants to be respected, she wants to be sensual and her nakedness is the less sensual thing she has. She is brilliant, but she is misunderstood. She is very much the representation of my inside self, who I don’t want to be, locked in my brain."
          souncloudUrl="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/67758049&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          src={artworks[3].src}
        />
        <ArtItem
          title="Blood"
          description="She lost her child that for a moment made her feel alive, capable of something bigger. She is so upset about it, she got crazy and lost her mind, her thoughts on it are blurry as you can see. Her child is immense for a newborn because it represents hope. She lost something big, but she keeps pushing. She has a moment of enlightenment before darkness comes after her, she thinks on the sacredness of being only nocturnal, which is life and not the world; of the still animal (or whatever it is) that does not see the light and for whom the womb becomes a tomb. It’s a mystery and it will always remain that way because we are minuscule compared to the mysteries of divinity (which is life itself). With death, apparently, comes darkness, nothingness. We go through the longest of the nights, but remember: even the darkest night will end and the Sun will rise again."
          souncloudUrl="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/67759424&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          src={artworks[0].src}
        />
        <ArtItem
          title="Aube"
          description="
“Aube” is the sun chasing the shadows away. My own sun, the brightest one. My morning-star. He is emerging from the gloom and he is painted in a more realistic way, with different pigments and techniques because on him there’s no confusion anymore, the sound is a very calming beat, constant. Just light. That morning-star is you."
          souncloudUrl="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/67759429&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          src={artworks[4].src}
        />
        <ArtItem
          title="Love"
          description="
“Love” is a more thrilling melody, like the waves on a stormy sea. The lovers hold each other while sinking. A shrill sound overwhelms them, but she doesn't let him go and he is unable to leave. They both will inevitably drown, the night is coming back and the Moon embraces the Sun which is also her child, he is confused and sleepy, she is wise and protective. Aube is losing his light. Bonne nuit."
          souncloudUrl="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/67759430&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          src={artworks[2].src}
        />
      </Grid>
    </Page>
  );
};

export default FeelingsPage;
